html, body {
  height: 100vh;
  margin: 0;
  background-color: #F9f9f9;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Fonts */

h1 {
  font-family: 'Gentium Plus', serif;
  color: #373C48;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  margin: 0;
}

h2 {
  font-family: 'Gentium Plus', serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

h3 {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: auto;
  margin: 0;
}

h4 {
  font-family: 'Gentium Plus', serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
  padding-bottom: 8px;
}

h5 {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: auto;
  margin: 0;
}

p, li {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: auto;
  margin: 0;
  width: 100%;
}

.bold {
  font-weight: 600;
}

.secondary {
  color: #686E78
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Navigation specific*/

.nav {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 8px;
  background-color: rgba(249, 248, 246, 0.5);
  backdrop-filter: blur(5px);
  margin: 40px 40px 0px 0px;
  padding: 0;
  border-radius: 80px;
  overflow: hidden;
}

.nav-links li{
  padding: 12px 16px;
  border-radius: 16px;
  overflow: hidden;
  width: auto;
}

.nav-links li:hover {
  background-color: #B6C5D3;
  color: #373C48;
}

.nav-links li a {
  text-decoration: none;
  color: #373C48;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Source Sans 3', sans-serif;
  font-style: normal;
  line-height: normal;
}

.nav-links li .highlight {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 10px;
}

/* Project Grid specific */

#work {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  padding: 40px;
  align-items: flex-start;
}

.projectLarge {
  display: flex;
  border-radius: 24px;
  overflow: hidden;
  flex: 1 1 calc(50% - 20px);  
  height: 400px;  
  text-align: left;
}

button {
  font-size: 16px;
  line-height: auto;
  font-family: 'Source Sans 3', sans-serif;
  backdrop-filter: blur(10px);
}

/* Project Page specific */

.project {
  background-color: #EAEAF6;
  text-align: left;
}

header {
  width: auto;
  padding: 240px 80px 80px 80px;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

header h1, header h3 {
  max-width: 960px;
}

.project h4 {
  width: 100%;
}

.project ul, .project li {
  margin: 0;
  padding: 0;
  margin-left: 16px;
  width: 100%;
}

.projectContent {
  background-color: #F9f9f9;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.projectIntroduction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 80px;
  max-width: 960px;
  align-self: center;
}

.introSection {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.projectContentSection {
  max-width: 960px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  padding-top: 80px;
}

.designProcessBlock {
  padding: 16px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  gap: 16px;
}

.projectGallery {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 40px;
}

.visualProjectGallery {
  flex: 0 0 30%;
}

.designProcessSummary {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 16px 0px;
}

.dpItem {
  background-color: #EAEAF6;
  padding: 16px 24px;
  border-radius: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dpItem ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 16px;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  line-height: auto;
}

.dpItem li {
  margin: 0;
  padding: 0;
}

.dpItem li::marker {
  content: '‣  ';
}

.imageLabel {
  font-size: 14px;
  color: #686E78;
}

.impactBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid rgb(104, 110, 120, 0.5);
  padding: 24px 32px;
  margin-top: 32px;
} 

.impactItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Intro specific */

.introContainer {
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 160px;
  padding: 80px;
  max-width: 960px;
}

/* Experience specific */

.jobContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin: auto;
  max-width: 960px;
  text-align: left;
  gap: 40px;
}

.job li {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-family: 'Source Sans 3', sans-serif;
}

.contact {
  background-color: #EAEAF6;
  max-width: 960px;
  width: 80%;
  padding: 48px;
  border-radius: 40px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
}

/* Responsive layout - makes a two column-layout instead of four columns */

@media (max-width: 800px) {
  .projectLarge {
    display: flex;
    border-radius: 24px;
    overflow: hidden;
    flex: 100%;  
    height: 400px;  
    text-align: left;
  }

  h1 {
    font-family: 'Gentium Plus', serif;
    color: #373C48;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    margin: 0;
  }


h2 {
  font-family: 'Gentium Plus', serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

h3 {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: auto;
  margin: 0;
}

h4 {
  font-family: 'Gentium Plus', serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
  padding-bottom: 8px;
}

h5 {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: auto;
  margin: 0;
}

  .introContainer {
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 80px;
    padding: 40px;
  }

/* Project specific */

.projectContent {
  padding: 40px 40px 80px 40px;
}

.projectIntroduction {
  flex-direction: column;
  gap: 40px;
}

.projectGallery {
  gap: 24px;
  flex-direction: column;
  align-items: center;
}

.impactBlock {
  flex-direction: column;
}

/* Experience specific */

  #work {
    padding: 40px;
  }

  .jobContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: auto;
    max-width: 100%;
    text-align: left;
    gap: 24px;
  }

  .contact {
    background-color: #EAEAF6;
    max-width: 960px;
    width: 80%;
    padding: 32px;
    border-radius: 40px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
    align-self: center;
  }
}

/* Mobile */

@media (max-width: 480px) {
  h1 {
    font-family: 'Gentium Plus', serif;
    color: #373C48;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
  }


h2 {
  font-family: 'Gentium Plus', serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

h3 {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: auto;
  margin: 0;
}

h4 {
  font-family: 'Gentium Plus', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  padding-bottom: 8px;
}

h5 {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: auto;
  margin: 0;
}

  /* Intro specific */

  .introContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 40px;
    padding: 20px;
  }

  #work {
    padding: 80px 20px;
  }

  /* Experience specific */

  .jobContainer {
    display: flex;
    align-items: 'flex-start';
    justify-content: flex-start;
    flex-direction: column;
    margin: auto;
    max-width: 100%;
    text-align: left;
    gap: 16px;
  }

  .contact {
    width: 100%;
    padding: 24px;
    border-radius: 24px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
    align-self: center;
  }

  /* Nav specific */

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    text-align: center;
    bottom: 24px;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 0px;
    backdrop-filter: blur(5px);
    margin: 0;
    padding: 0;
    border-radius: 80px;
    overflow: hidden;
    align-self: center;
  }
  
  .nav-links li{
    padding: 12px 16px;
    border-radius: 16px;
    overflow: hidden;
  }
  
  .nav-links li:hover {
    background-color: #B6C5D3;
    color: #373C48;
  }
  
  .nav-links li a {
    text-decoration: none;
    color: #373C48;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    line-height: normal;
  }
  
  .nav-links li .highlight {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 10px;
  }
  
  /* Project Grid specific */

  .projectLarge {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    flex: 100%;  
    height: 400px;  
    text-align: left;
  }
}

@media (min-width: 1920px) {
  .projectLarge {
    flex: 0 0 24.24%;  
  }
}